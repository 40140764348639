import { FC, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { trendsForYouStore } from '@modules/home/adapter/outbound/stores/trendsForYou.store';
import { ITikTokTrend } from '@modules/trends/domain/models/tiktok.model';
import { Title } from '@components/title';
import { ITab } from '@shared/models/tab.model';
import { Container, TabWrapper, TitleAndTab } from './TrendHotTikTok.style';
const Tab = dynamic(() => import('@components/tab/Tab'));
const TrendTikTok = dynamic(() => import('@components/trend/trendTiktok/TrendTikTok'));
interface Props {
  onTabChange: (hashtag: string) => void;
  tiktokHashtagList: ITab[];
  data: ITikTokTrend[];
  activeKey: string;
  setActiveKey: (hashtag: string) => void;
}
const TrendHotTikTok: FC<Props> = ({
  tiktokHashtagList,
  data,
  onTabChange,
  activeKey,
  setActiveKey
}: Props) => {
  const {
    resetTrendsPopoverState
  } = trendsForYouStore(state => state);
  useEffect(() => {
    if (tiktokHashtagList.length > 0) {
      setActiveKey(tiktokHashtagList[0].key as string);
    }
  }, [tiktokHashtagList]);
  useEffect(() => {
    onTabChange(activeKey);
  }, [activeKey]);
  return <Container onClick={() => resetTrendsPopoverState()}>
            <TitleAndTab>
                <Title text="เทรนด์ฮอต TikTok 🔥" />
                {tiktokHashtagList.length > 0 && <TabWrapper>
                        <Tab items={tiktokHashtagList} activeKey={activeKey} setActiveKey={setActiveKey} eventSection="Trend Hot Tiktok" />
                    </TabWrapper>}
            </TitleAndTab>
            {data && <TrendTikTok data={data} />}
        </Container>;
};
export default TrendHotTikTok;