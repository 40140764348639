import { FC } from 'react';
import { ICpConnect } from '@modules/cpConnect/domain/models/cpConnect.model';
import { SeeAll } from '@components/seeAll';
import { Title } from '@components/title';
import { analytics } from '@shared/utils/segment.utils';
import { CpConnectCard } from '..';
import { Container, WrapperCard } from './HomeCpConnect.style';
interface Props {
  itemList: ICpConnect[];
}
const HomeCpConnectLayout: FC<Props> = ({
  itemList
}: Props) => {
  const onClickConnectCard = (title: string, position: number) => {
    analytics.track('CP Connect Click', {
      event: 'CP Connect Click',
      position,
      cp_connect_name: title
    });
  };
  return <Container>
            <Title text="ซีพี คอนเนค 🎪" subText="เชื่อมต่อเว็บไซต์ในเครือซีพี" extra={<SeeAll url="/cp-connect" onClick={() => {
      analytics.track('CP Connect See All Click', {
        event: 'CP Connect See All Click'
      });
    }} />} />
            <WrapperCard>
                {itemList?.map((item, index) => {
        return <CpConnectCard key={index} item={item} onClick={() => {
          onClickConnectCard(item.title, index + 1);
          analytics.track('Offsite Linked', {
            event: 'Offsite Linked',
            destination_url: item.linkoutUrl
          });
        }} />;
      })}
            </WrapperCard>
        </Container>;
};
export default HomeCpConnectLayout;